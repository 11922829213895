<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative;">
    </div>
</template>

<script>

import Anychart from 'anychart';
import './AnychartThemes/market_forecast.js'

export default {
    props: [
        'data',
        'year',
        'firstYear',
        'lastYear',
        'topicName',
        'segmentationName',
        'valueDisplayOption',
        'FYValues',
        'LYValues',
        'index1',
        'yearIndex',
        'decimalsOption',
    ],
    emits: ['next-step','addChartsForExportWord'],
    name: 'VueAnychartFigure3PieChart',

    components:{
    },
    data(){
        return{
            chart:null,
            pie:null,

            // chartFY:null,
            total:null,
        }
    },

    mounted(){
        this.sumYearsValue();
        this.init();
        // this.$emit('next-step'); //TODO
        // this.addChartsForWord(); //TODO
        
    },
    methods:{
        toFixDecimate3(cell) {
            if(this.decimalsOption === '0.'){
                if(Number.isInteger(cell)){
                    return cell
                }
                else{
                    return Math.round(cell);
                }
            }
            if(this.decimalsOption === '.0'){
                return this.roundTo(cell, 1).toFixed(1) 
            }
            if(this.decimalsOption === '.00'){
                return this.roundTo(cell, 2).toFixed(2)     
            }
            if(this.decimalsOption === '.000'){
                return this.roundTo(cell, 3).toFixed(3)     
            }
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor)/factor;
        },


        sumYearsValue(){// get total of totalFy and totalLY
            if(this.FYValues){
                let fy = this.FYValues.reduce((acc, value)=> acc + parseFloat(value),0);
                fy = this.toFixDecimate3(fy);
                this.total = this.formatNumber(fy)
            }
            else{
                let ly = this.LYValues.reduce((acc, value)=> acc + parseFloat(value),0);
                ly = this.toFixDecimate3(ly);
                this.total = this.formatNumber(ly)
            }
        },
        init(){
            let _Anychart = Anychart;
            this.chart = _Anychart;

            this.chart.theme('marketForecast');
            
            let dataSet = this.chart.data.set(this.data);
            let SeriesFY = dataSet.mapAs({ x: 0, value: this.yearIndex });
            
            this.pie = this.chart.pie(SeriesFY);
            
            // LICENSE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 
            // const currentYear = new Date().getFullYear();
            this.pie
                .credits()
                .enabled(false)
                // .url('https://www.marketforecast.com')
                // .logoSrc('https://www.marketforecast.com/favicon.ico')
                // .text(
                //     `© Market Forecast ${currentYear}`
                // );


            // Set chart margin settings
            this.pie.padding(5, 5, 5, 5);

            // Configure labels
            this.pie.labels().position('outside');

            this.pie.labels().format(
                function(){
                    // get Actual value of the text
                    let currentLabel = this.getData('x');
                    // let value = this.getData('value');
                    let percentage = this.getStat('percentValue').toFixed(2)+'%';
                    return currentLabel+ " " + percentage; 
                }
            )

            // set chart legend settings
            this.pie.legend().enabled(false);
            this.pie.background().stroke(null);
            this.pie.innerRadius("30%");

            const FY = this.firstYear
            const valueDisplayOption = this.valueDisplayOption

            // chartFY Title

            if(this.yearIndex === 1){
                this.pie
                    .title()
                    .enabled(true)
                    .text(`${FY} US$ ${this.total} ${this.valueDisplayOption}`)
                    .orientation("bottom")
                    .align("center")
                    .fontSize(12)

                this.pie.label()
                    .enabled(true) // Habilitar la etiqueta
                    .text(FY) // Mostrar el valor de FY en la etiqueta
                    .position("center") // Posicionar en el centro del gráfico
                    .anchor("center") // Asegurar el anclaje en el centro
                    .hAlign("center") // Alinear horizontalmente al centro
                    .vAlign("middle") // Alinear verticalmente al centro
                    .offsetY(-10)
                    .fontColor("#003A70") // Establecer el color de la fuente
                    .fontWeight("bold") // Establecer la fuente en negrita
                    .fontSize(14); // Tamaño de la fuente

            }else{
                // console.log(this.total);
                this.pie
                    .title()
                    .enabled(true)
                    .text(`${this.lastYear} US$ ${this.total} ${this.valueDisplayOption}`)
                    .orientation("bottom")
                    .align("center")
                    .fontSize(12)

                this.pie.label()
                    .enabled(true) // Habilitar la etiqueta
                    .text(this.lastYear) // Mostrar el valor de FY en la etiqueta
                    .position("center") // Posicionar en el centro del gráfico
                    .anchor("center") // Asegurar el anclaje en el centro
                    .hAlign("center") // Alinear horizontalmente al centro
                    .vAlign("middle") // Alinear verticalmente al centro
                    .offsetY(-10)
                    .fontColor("#003A70") // Establecer el color de la fuente
                    .fontWeight("bold") // Establecer la fuente en negrita
                    .fontSize(14); // Tamaño de la fuente
            }


            

            // Configure tooltips
            this.pie.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            this.pie.tooltip().title().fontSize(14); // Set tooltip font size
            this.pie.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            this.pie.tooltip().fontSize(12); // Set tooltip font size
            this.pie.tooltip().padding(10); // Set tooltip padding
            this.pie.tooltip().format("{%Value}"); // Include the name, value, and percentage in the tooltip

            // Customize the tooltip title and content
            const year = (this.yearIndex === 1 )? FY : this.lastYear
            this.pie.tooltip()
                .titleFormat(
                    function () {
                        return '' + this.getData('x');
                    }
                )
                .format(
                    function () {
                        
                        
                        return `${year } : ` + Anychart.format.number(this.getData('value'), {
                            groupsSeparator: ',',
                            decimalSeparator: '.'
                        }) + ` US$ ${valueDisplayOption}`;
                    }
                );

            // Set a white border around each slice
            this.pie.stroke("1 #FFFFFF");  
            this.pie.container(this.$refs.chartContainer).draw();
        },
        formatNumber(num){
            if(num){
                // split int and decimals of number
                let [int , decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },
    }
}
</script>
<style scoped>
    
</style>