<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative;">
    </div>
    <!-- <div style="position: absolute; top: -10; left: 10%; z-index: 2;"> -->
    <ExportSettingsDialog 
        :algo="'something'"
        @exportToSvg="exportToSvgEmit"
    />
    <!-- <Button
        @click="exportToDoc()"
    >
        Export To Doc
    </Button> -->
</template>

<script>
import Anychart from 'anychart';
// import Button from 'primevue/button';
import ExportSettingsDialog from './ExportSettingsDialog.vue'

import './AnychartThemes/market_forecast.js'

export default {
    props: ['data', 'years','maxValue','valueDisplayOption','topicName','segmentationName'],
    emits: ['next-step'],
    name: 'VueAnychartFigure1',
    components: {
        ExportSettingsDialog,
        // Button
    },

    data() {
        return {
            chart: null,
            column: null,
            chartError:false,
        }
    },
    mounted() {
        // console.log('this.data',this.data);
        this.init();
        this.$emit('next-step');
    },

    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
    },
    methods: {
        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        // delegateMethod(name, data) {
        //     if (!this.chart) {
        //         warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this);
        //         return
        //     }

        //     return this.chart[name](data)
        // },
        init(){
            let _Anychart = Anychart; 
            this.chart = _Anychart;

            // TODO: TEST ON ALL CHARTS
            let newData = this.data
            // newData.map(items => items[items.length - 1] );
            newData.map(subArray => {
                let lastIndex = subArray.length - 1;
                subArray[lastIndex] = subArray[lastIndex].toString().replace('%', '');
                return subArray;
            });

            let dataSet = this.chart.data.set(this.data)

            // map data for the first series, take x from the zero column and value from the first column of data set
            let seriesFY = dataSet.mapAs({x:0, value:1});
            // map data for the second series, take x from the zero column and value from the second column of data set
            let seriesLY = dataSet.mapAs({x:0, value:2});
            // map data for the second series, take x from the zero column and value from the second column of data set
            let seriesCAGR  = dataSet.mapAs({x:0, value:3});

            // create column chart 
            this.column = this.chart.column();

            // themes 
            
            this.chart.theme('marketForecast');
            console.log('figure1',this.chart.theme());

            // license Key
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c')

            const currentYear = new Date().getFullYear();

            // credits 
            this.column
                .credits()
                .enabled(true)
                .url('https://www.marketforecast.com')
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                  `© Market Forecast ${currentYear}`
                ); 

            // column properties
            // column title // hide column Title
            // this.column
            //     .title()
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([0,0,10,0])
            //     .text(
            //         `<span style="font-size: 16px;font-weight:bold;">${this.topicName} Market Summary by ${this.segmentationName}</span>` +
            //         '<br/><span  style="color:#929292; font-size: 12px;">' +
            //         `Market Size ${this.years[0]}, Market Size ${this.years[1]}, CAGR ${this.years[0]}-${this.years[1]}</span>`
            //     );
            
            // set Chart margin Setting 
            // this.column.padding(20,20,10,20);
            this.column.padding(20,5,5,5); //TODO check top padding
 


            

            // grid settings
            this.column
                .yGrid(true)
                .xGrid(true)
                .xMinorGrid(true)
                .yMinorGrid(true);

            // Set Column legend settings
            this.column.legend().enabled(true).padding({top:10, bottom: 30});
            this.column.legend().position("bottom");


            // ROTATE EXAMPLE
            // this.column.xAxis().labels().rotation(-90); 

            // enabling stagger mode
            this.column.xAxis().staggerMode(true);
            // this.column.xAxis().staggerLines(2);
            this.column.xAxis().staggerMaxLines(4);

            // yAxis

            this.column.yAxis(0)
            this.column.yAxis(0)
                .title(`Market size in US$ ${this.valueDisplayOption}`)
                .title().padding(10)
                .fontColor("#385D8A");

            this.column
                .yAxis(0)
                .labels()
                .format(function(){
                    return Anychart.format.number(this.value, {
                        groupsSeparator: ','
                    });
                })
            
            this.column
                .yAxis(1)
                .title(`CAGR ${this.years[0]}-${this.years[1]} - Percentage`)
                .title().padding(10) // Adding padding to Y-axis title
                .fontColor("#003A70");
            
            this.column
                .yAxis(1)
                .labels()
                .format("{%Value}{decimalsCount:1}%")
                

            // setting Grid settings 
            this.column.xGrid(true);
            this.column.yGrid(true);

            // adding extra Y Scale 
            let extraYScale = this.chart.scales.linear();
            extraYScale.minorTicks().interval(2);


            // adding and adjust extra y Axis
             let extraYAxis = this.column.yAxis(1);
             extraYAxis.orientation("right");
             extraYAxis.scale(extraYScale)  

            // setting and adjusting data visualizzation
            let columnSeriesFY = this.column.column(seriesFY);
            columnSeriesFY.name(`${this.years[0]}`);

            let columnSeriesLY = this.column.column(seriesLY); 
            columnSeriesLY.name(`${this.years[1]}`);

            let markerSeriesCAGR = this.column.marker(seriesCAGR); 
            markerSeriesCAGR.name(`CAGR ${this.years[0]}-${this.years[1]}`);
            markerSeriesCAGR.yScale(extraYScale)

            // Add labels to markerSeriesCAGR
            markerSeriesCAGR.labels().enabled(true);
            markerSeriesCAGR.labels().anchor('center-bottom');
            markerSeriesCAGR.labels().format("{%Value}%"); // Customize the label format if needed
            markerSeriesCAGR.labels().offsetY(15);
            markerSeriesCAGR.labels().padding(5);
            markerSeriesCAGR.labels().background().enabled(true).fill("#FFFFFF"); // Add background color

            // set the size of markers
            markerSeriesCAGR.normal().size(10);
            markerSeriesCAGR.hovered().size(15);
            markerSeriesCAGR.selected().size(15);  
            markerSeriesCAGR.normal().type("circle");
            markerSeriesCAGR.hovered().type("circle");
            markerSeriesCAGR.selected().type("circle");  
            // markerSeriesCAGR.normal().fill("#003A70", 0.7);
            markerSeriesCAGR.normal().fill("#003A70", 1);
            // markerSeriesCAGR.hovered().fill("#003A70", 0.5);
            markerSeriesCAGR.hovered().fill("#003A70", 0.8);
            // markerSeriesCAGR.selected().fill("#003A70", 0.9);     
            markerSeriesCAGR.selected().fill("#003A70", 0.6); 
            
            markerSeriesCAGR.normal().stroke("#ffffff", 1, "", "");
            markerSeriesCAGR.hovered().stroke("#ffffff", 1, "", "");
            markerSeriesCAGR.selected().stroke("#ffffff", 1, "", "");
            

            // columnSeriesFY.normal().fill("#385D8A", 0.7);
            columnSeriesFY.normal().fill("#385D8A", 1);
            // columnSeriesFY.hovered().fill("#385D8A", 0.5);
            columnSeriesFY.hovered().fill("#385D8A", 0.8);
            // columnSeriesFY.selected().fill("#385D8A", 0.9);  
            columnSeriesFY.selected().fill("#385D8A", 0.6);  

            columnSeriesFY.normal().stroke("#ffffff", 1, "", "");
            columnSeriesFY.hovered().stroke("#ffffff", 1, "", "");
            columnSeriesFY.selected().stroke("#ffffff", 1, "", "");

            
            // columnSeriesLY.normal().fill("#A1B4D4", 0.7);
            columnSeriesLY.normal().fill("#A1B4D4", 1);
            // columnSeriesLY.hovered().fill("#A1B4D4", 0.5);
            columnSeriesLY.hovered().fill("#A1B4D4", 0.8);
            // columnSeriesLY.selected().fill("#A1B4D4", 0.9);  
            columnSeriesLY.selected().fill("#A1B4D4", 0.6);  

            // REMOVE BORDER ON LY SERIES with using themes
            //TODO: on all the charts
            columnSeriesLY.normal().stroke("#ffffff", 1, "", "");
            columnSeriesLY.hovered().stroke("#ffffff", 1, "", "");
            columnSeriesLY.selected().stroke("#ffffff", 1, "", "");


            // Set global tooltip styles
            // this.column.tooltip().background().fill("#C0C0C0"); // Set tooltip background color
            
            // this.column.tooltip().title().fontColor("#003A70"); // Set tooltip text color
            this.column.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            this.column.tooltip().title().fontSize(14); // Set tooltip font size
            // this.column.tooltip().fontColor("#003A70"); // Set tooltip text color
            this.column.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            this.column.tooltip().fontSize(12); // Set tooltip font size
            this.column.tooltip().padding(10); // Set tooltip padding

            // Customize the tooltip for markerSeriesCAGR to include a percentage sign
            markerSeriesCAGR.tooltip().format("CAGR: {%Value}%");

            // create variables to assign tooltip 
            const firstYear =this.years[0]
            const lastYear = this.years[1]
            const valueDisplayOption = this.valueDisplayOption

            // Customize the tooltip for columnSeriesFY to include custom text and formatted value

            columnSeriesFY.tooltip().format(function() {
                return `Market size ${firstYear}: ` + Anychart.format.number(this.value, { 
                    groupsSeparator: ',', 
                    decimalSeparator: '.' 
                }) + ` US$ ${valueDisplayOption}`;
            });

            // Customize the tooltip for columnSeriesLY to include custom text and formatted value
            columnSeriesLY.tooltip().format(function() {
                return `Market size ${lastYear}: ` + Anychart.format.number(this.value, { 
                    groupsSeparator: ',', 
                    decimalSeparator: '.' 
                }) + ` US$ ${valueDisplayOption}`;
            }); 

            // this.column.container(this.$el).draw();
            this.column.container(this.$refs.chartContainer).draw();

            this.column.contextMenu().itemsFormatter(
                function(items){
                    // modify save as png
                    items['save-chart-as'].subMenu['save-chart-as-png'].action = function() {
                        this.column.saveAsPng(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "filename":'SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    // modify save as jpg
                    items['save-chart-as'].subMenu['save-chart-as-jpg'].action = function() {
                        this.column.saveAsJpg(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "forceTransparentWhite": false,
                                "filename":'SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 

                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-svg'].action = function() {
                        this.column.saveAsSvg(
                            // {
                            //     "paperSize": 'a4',
                            //     "landscape": true,
                            // }
                            {
                                "width": 1000,
                                "height": 1000,
                                "filename":'SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 


                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-pdf'].action = function() {
                        this.column.saveAsPdf(
                            {
                                "paperSize": 'a4',
                                "landscape": true,
                                "filename":'SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    return items;
                }.bind(this)
            )
            // this.chart.exports.filename(this.topicName+' '+'Market summary by'+' '+ this.segmentationName); //change name of exports  
            this.chart.exports.filename('SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName); //change name of exports  
            this.checkFontSettings(this.column);
        },

        checkFontSettings(chart) {
            // Obtiene la configuración de las etiquetas del eje X
            const xAxisLabels = chart.xAxis().labels();
            const fontFamily = xAxisLabels.fontFamily();
            const fontColor = xAxisLabels.fontColor();
            const fontSize = xAxisLabels.fontSize();

            console.log('Font Family:', fontFamily);  // Debería ser '"Arial", monospace'
            console.log('Font Color:', fontColor);    // Debería ser '#003A70'
            console.log('Font Size:', fontSize);      // Debería ser 11

            // Verifica si se están aplicando los valores del tema
            if (fontFamily === '"Arial", monospace' && fontColor === '#003A70' && fontSize === 11) {
            console.log('Los ajustes de defaultFontSettings se están aplicando correctamente.');
            } else {
            console.log('Los ajustes de defaultFontSettings NO se están aplicando.');
            }
        },


        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height){
            this.column.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'SS01_'+this.topicName+'_'+'Market_summary_by_'+this.segmentationName,
                }
            )
        },

        // async exportToDoc() {
        //     let svgChart= this.column.toSvg();
        //     console.log(svgChart);

        //     const preHtml = `
        //     <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        //     <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;
        //     const postHtml = "</body></html>";
        //     const html = preHtml+ svgChart + postHtml;

        //     const blob = new Blob(['\ufeff', html], {
        //     type: 'application/msword'
        //     });

        //     // Especifica el nombre del archivo
        //     const url = URL.createObjectURL(blob);
        //     const a = document.createElement('a');
        //     a.href = url;
        //     a.download = 'exported-document.doc';
        //     document.body.appendChild(a);
        //     a.click();
        //     URL.revokeObjectURL(url);
        // },
        svgToPng(svg, callback){
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                URL.revokeObjectURL(url);
                callback(canvas.toDataURL('image/png'));
            }
            img.src = url;
        },
        
        async exportToDoc() {
            let svgChart = this.column.toSvg();

            this.svgToPng(svgChart, (pngDataUrl) => {
                const preHtml = `
                <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;
                const postHtml = "</body></html>";

                // Inserta la imagen PNG en lugar del SVG
                const html = `${preHtml}<img src="${pngDataUrl}" />${postHtml}`;

                const blob = new Blob(['\ufeff', html], {
                    type: 'application/msword'
                });

                // Especifica el nombre del archivo
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'exported-document.doc';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            });
        },

        // potential Solution 
        // function svgToPng(svg, callback) {
        //     const canvas = document.createElement('canvas');
        //     const ctx = canvas.getContext('2d');
        //     const img = new Image();
        //     const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
        //     const url = URL.createObjectURL(svgBlob);

        //     img.onload = () => {
        //         canvas.width = img.width;
        //         canvas.height = img.height;
        //         ctx.drawImage(img, 0, 0);
        //         URL.revokeObjectURL(url);
        //         callback(canvas.toDataURL('image/png'));
        //     };

        //     img.src = url;
        // }
        
        // async exportToDoc() {
        //     const charts = [this.chart1, this.chart2, this.chart3, this.chart4, this.chart5, this.chart6]; // Reemplaza con tus gráficos reales
        //     const pngDataUrls = [];

        //     const convertChartToPng = (chart) => {
        //         return new Promise((resolve) => {
        //             svgToPng(chart.toSvg(), (pngDataUrl) => {
        //                 resolve(pngDataUrl);
        //             });
        //         });
        //     };

        //     for (let chart of charts) {
        //         const pngDataUrl = await convertChartToPng(chart);
        //         pngDataUrls.push(pngDataUrl);
        //     }

        //     const preHtml = `
        //     <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        //     <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;
        //     const postHtml = "</body></html>";

        //     let bodyContent = '';

        //     for (let pngDataUrl of pngDataUrls) {
        //         bodyContent += `<img src="${pngDataUrl}" /><br>`;
        //     }

        //     const html = `${preHtml}${bodyContent}${postHtml}`;

        //     const blob = new Blob(['\ufeff', html], {
        //         type: 'application/msword'
        //     });

        //     // Especifica el nombre del archivo
        //     const url = URL.createObjectURL(blob);
        //     const a = document.createElement('a');
        //     a.href = url;
        //     a.download = 'exported-document.doc';
        //     document.body.appendChild(a);
        //     a.click();
        //     URL.revokeObjectURL(url);
        // }

    },
    watch: {
        // 'data': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
    },
    beforeUnmount() {
        console.log('unMount Component');
        if (this.chart) {
            this.column.dispose();
            this.chart.theme(null);
            this.chart = null;
            this.column = null;
        }
    }
}
</script>

<style></style>